.hero-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #eeeded; /* Background color to blend with image */
  overflow: hidden;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3; /* Control the blending effect */
}

.text-container {
  position: relative;
  z-index: 2;
  color: #333;
  text-align: center;
  padding: 20px;
}

.text-container h1 {
  font-size: 4rem;
  margin-bottom: 10px;
}

.text-container p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.login-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}
