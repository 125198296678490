html, body, #root {
    height: 100%;
    margin: 0;
  }
  
  .app-container {
    display: flex;
    height: 100%;
    width: 100%;
  }
  
  .padding-4rem{
    padding: 2rem !important;
  }
  .sidebar {
    width: 200px;
    background-color: #0B5676;
    padding: 15px;
    height: 100%;
    box-sizing: border-box;
  }
  
  .content {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 10px 0;
  }
  
  .sidebar ul li a {
    text-decoration: none;
    color: #fff;
  }
  

  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

  .fixed-pagination .ant-pagination {
    position: sticky;
    bottom: 0;
    background-color: white; /* or your background color */
    z-index: 1;
  }
  
  .fixed-pagination .ant-table-body {
    max-height: calc(100vh - 250px); /* Adjust based on your layout */
    overflow-y: auto;
  }
  
  